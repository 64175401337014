import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PreHeading from "../components/PreHeading";
import ArticleSection from "../components/ArticleSection";
import ArticlePage from "../components/ArticlePage";
import FooterJumbotron from "../components/FooterJumbotron";

const HowItWorks = () => (
  <Layout>
    <SEO title="How it works?" />
    <ArticlePage title={'How it works?'}>
    <ArticleSection className="text-center">
        <PreHeading>
          Know your rights
        </PreHeading>
        <h2>
          Passenger rights
        </h2>
        <p>Millions of passengers used to be affected by different types of disruptions to their flights and there were barely any consequences for the airlines involved. This all changed when the European Parliament and Council created what is now known as Regulation EC 261/2004 in order to protect air passengers’ rights.
        </p>
        <p>The Regulation EC 261/2004 provides the rules and criteria from which it will be determined if the airlines need to pay compensation to their passengers when they’ve experienced a flight cancellation or if they have been denied boarding. It was not until the Sturgeon ruling when it was established that flight delays greater than 3 hours should be treated and compensated in a similar way as cancelled flights.
        </p>
      <h2>Delayed flight</h2>
      <p>A delayed flight can be a big hassle. Imagine that you are about to leave or return from your holiday destination, business trip or international visit and you get the following news: your flight is delayed for more than three hours. "Why does this have to happen to me?", you might ask yourself. But there's an upside which could potentially turn that frown upside down: you are probably entitled to a flight delay compensation!
      </p><p>
  
      What to do? According to EU Regulation EC 261/2004, passengers are entitled to compensation when their flight was delayed, depending on the exact reason why their flight was delayed. Are you wondering if you’re entitled as well? Simply submit the details of your delayed flight in our claim calculator below to see if you can claim up to €600.- per person. This check is always free of charge and non-committal!</p>
      <h2>Cancelled flights</h2>
      <p>A cancelled flight can be the result of bad weather conditions, a strike, a technical defect or a fault in the booking system of the airline. The list of reasons can - unfortunately - go on and on. However, there may actually be a positive side to all these reasons: you are probably entitled to a cancelled flight compensation! Thanks to European regulation, your rights regarding cancelled flights are protected. This means that there might be a compensation up to 532,- pounds (€600.-) waiting for you. Confused what to do? We are here to help! The EU Regulation EC 261/2004 states that passengers are entitled to a compensation when their flight is cancelled depending on the exact reasons for the cancellation. In the event of a cancellation, the airline is always required to offer passengers either a new flight to the destination or a refund for your original airline ticket. So just because the airline offers you a new flight (and/or care), doesn't mean you're no longer entitled to receive financial compensation as a result of your cancelled flight as well.
      </p><p>
        When your flight is cancelled by the airline, they are required to either offer you a refund for your original ticket or offer you a new flight to your destination. In addition you are probably entitled to compensation if the cancellation was not caused by extraordinary circumstances such as bird strike, a volcanic eruption or COVID-19. So just because your airline offered you a new flight (and/or care), that doesn't mean that you are no longer entitled to compensation! You can still get up to € 600,- per person.</p>
    <h2>Denied Boarding</h2>
      <p>Were you denied boarding? That could mean several things: there were more tickets sold than there are seats or something went wrong with your travel documents. Both cases of denied boarding can lead to stressful situations you don’t want to be in right before boarding. But don’t worry, Yource is here to help you to get the care you deserve in case of denied boarding. According to the EU Regulation EC 261/2004 your traveller rights are protected when you were denied on your flight and in several cases of denied boarding you are eligible for receiving compensation up to 600 euros per person.</p>
    </ArticleSection>
      <FooterJumbotron noSkew />
    </ArticlePage>
  </Layout>
)

export default HowItWorks
